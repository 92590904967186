import { useCallback, useMemo } from "react"
import { getGatsbyImageData } from "gatsby-source-sanity"
import { useConfigContext } from "@app/providers/config"
import { useCore } from "@app/hooks/useCore"
import { useMedia } from "@app/hooks/useMedia"

export const useImage = () => {
  const { store } = useConfigContext()
  const {
    helpers: { handleize },
  } = useCore()
  const { isBase } = useMedia()

  const sanityConfig = useMemo(
    () => ({
      projectId: store.sanityProjectId,
      dataset: store.sanityDataset,
    }),
    [store]
  )

  const filename = useCallback(
    ({ alt, asset }) => `${handleize(asset?.originalFilename?.split(`.`)?.[0] || alt)}.${asset?.extension}`,
    [handleize]
  )

  const withFilename = useCallback(
    (fluidImage = {}, name = "image.jpg", properties = ["src", "srcSet"]) => ({
      ...fluidImage,
      ...properties?.reduce((o: any, key: string) => ({ ...o, [key]: fluidImage[key]?.split("?")?.join(`/${name}?`) }), {}),
    }),
    []
  )

  const getGatsbyImage = useCallback(
    (image: any, options: any = null) =>
      image?.asset?._id
        ? {
            alt: image?.alt,
            dimensions: image?.asset?.metadata?.dimensions,
            //@ts-ignore
            ...withFilename(getGatsbyImageData(image, options || {}, sanityConfig)?.images?.fallback, filename(image)),
          }
        : image,
    [filename, sanityConfig, withFilename]
  )

  const getResponsiveImage = useCallback(
    (image: any, options: any = null, responsivePoint: boolean = isBase) =>
      ({
        desktop: getGatsbyImage(image?.desktop, options?.desktop || options || {}),
        mobile: getGatsbyImage(image?.mobile?.asset ? image?.mobile : image?.desktop, options?.mobile || options?.desktop || options || {}),
      }[responsivePoint ? "mobile" : "desktop"]),
    [getGatsbyImage, isBase]
  )

  return { getGatsbyImage, getResponsiveImage }
}
